import { GetApiData } from "utils/http-client";
export const LoginForm = function (data) {
  return GetApiData(`/user/login`, "POST", data, true);
};
export const WaitingListForm = function (data) {
  return GetApiData(`/waiting-user/create`, "POST", data, true);
};
export const Forgot = function (data) {
  return GetApiData(`/user/forgot-password`, "POST", data, true);
};
export const ResetPasswordService = function (data) {
  return GetApiData(`/user/forgot-password`, "POST", data, true);
};
export const OTPCheck = function (data) {
  return GetApiData(`/user/otp-check`, "POST", data, true);
};
