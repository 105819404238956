import avatar from "assets/avatar.png";
import logo from 'assets/PestInsightsLogo_rectangle.png';
import React, { useEffect, useState } from 'react';
import { Container, Dropdown, Image } from 'react-bootstrap';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { GetUserName } from "utils/auth.utils";
import { useAuth } from 'utils/AuthContext';

const SidebarHeader = ({ onLogoClick }) => {
    const [showDropdown, setShowDropdown] = React.useState(false);
    const { logout } = useAuth();
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
    const fullName = GetUserName();
    const handleDropdownToggle = () => {
        setShowDropdown(prev => !prev);
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    useEffect(() => {
        const handleStorageChange = () => {
            setUser(JSON.parse(localStorage.getItem("user")) || {});
        };

        window.addEventListener('user-update', handleStorageChange);
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('user-update', handleStorageChange);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    return (
        <Container fluid>
            <div className="d-flex justify-content-between align-items-center">
                <Link onClick={onLogoClick} className="d-lg-none my-auto">
                    <Image
                        src={logo}
                        alt='Logo'
                        className='w-100 side-logo'
                    // style={{ height: '65px', width: "44px" }}
                    />
                </Link>
                <div className='d-lg-inline-block d-none my-auto'>
                </div>
                <div>
                    <div className="d-flex align-items-center">


                        <Dropdown show={showDropdown} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle
                                as="div"
                                style={{ cursor: 'pointer' }}
                            />
                            <Dropdown.Menu className='border-0' style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                                <Dropdown.Item as={Link} to="/settings">
                                    <FaUser className="me-2" />
                                    Profile
                                </Dropdown.Item>
                                <Dropdown.Item onClick={logout} style={{ color: "var(--primary-color)" }}>
                                    <FaSignOutAlt className="me-2" />
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className='mx-2 d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={handleDropdownToggle}>
                            <div style={{ color: "var(--primary-color)", fontSize: "16px" }}>{`${capitalizeFirstLetter(user.firstname)} ${capitalizeFirstLetter(user.lastname)}`}</div>
                        </div>
                        <Image
                            onClick={handleDropdownToggle}
                            // src={avatar}
                            src={user.image ? user.image : `https://api.dicebear.com/7.x/initials/svg?seed=${fullName}&radius=50`}
                            alt='Avatar'
                            className=''
                            style={{ height: '40px', width: "40px", borderRadius: "60%" }}
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default SidebarHeader;
