import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import HeroBg from 'assets/icons/HeroBg.png'
import { useNavigate } from 'react-router-dom';
const Hero = () => {
    const navigate = useNavigate();

    return (
        <Container className="py-5" >
            <Row style={{
                backgroundImage: `url(${HeroBg})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }} className="justify-content-center text-center">
                <Col xxl={9} >
                    <h1 className='display-5 fw-bold text-uppercase text-black'>
                        Optimize Your Pest Control Operations with <span className='color-ttb'>Pest Insights</span>
                    </h1>
                    <p className='fs-5 py-md-3 py-0'>
                        Empowering pest control companies with insights into technician performance through automated reporting and data analytics
                    </p>
                    <div className='d-flex justify-content-center gap-md-4 gap-2 flex-md-row flex-column align-items-center'>
                        <Button variant="dark" className="px-5 py-3" onClick={() => navigate("/waiting-form")}>Get Started</Button>
                        <Button style={
                            { background: "var(--bg-ltr)", borderColor: "var(--primary-color)" }
                        } className="px-5 py-3" onClick={() => navigate("/login")}>Try Pest Insights</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Hero;
