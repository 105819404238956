import TestimonialCard from 'components/TestimonialCard';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import u1 from 'assets/avatar/u1.png'
import u2 from 'assets/avatar/u2.png'
import u3 from 'assets/avatar/u3.png'
const Testimonials = () => {
    const testimonials = [
        {
            id: 1,
            image: "https://cdn.pixabay.com/photo/2023/12/19/22/46/business-8458541_1280.jpg",
            name: 'Sarah M.',
            domain: 'Operations Manager',
            heading: "Invaluable Insights!",

            description: 'This software has completely transformed how we manage our team. We can easily track performance, which has led to more satisfied customers and increased revenue.',
        },
        {
            id: 2,
            image: u2,
            name: 'James R.',
            domain: 'Pest Control Owner',
            heading: "Boosted Efficiency!",

            description: 'With the route optimization and technician performance data, we’ve cut down on unnecessary mileage and improved our service times. The impact on our bottom line is undeniable.',
        },
        {
            id: 3,
            image: u3,
            name: 'Linda K. ',
            domain: 'General Manager',
            heading: "A Game-Changer!",

            description: 'This tool provides all the metrics we need in one place. It’s helped us make informed decisions, leading to better service and happier customers.',
        },
        // {
        //     id: 4,
        //     image: 'https://unsplash.com/photos/h4i9G-de7Po/download?force=true',
        //     name: 'Sarah M.',
        //     domain: 'Operations Manager',
        //     heading: "Invaluable Insights!",

        //     description: 'This software has completely transformed how we manage our team. We can easily track performance, which has led to more satisfied customers and increased revenue.',
        // },
        // {
        //     id: 5,
        //     image: 'https://unsplash.com/photos/rDEOVtE7vOs/download?force=true',
        //     name: 'James R.',
        //     domain: 'Pest Control Owner',
        //     heading: "Boosted Efficiency!",

        //     description: 'With the route optimization and technician performance data, we’ve cut down on unnecessary mileage and improved our service times. The impact on our bottom line is undeniable.',
        // },

    ];

    return (
        <div className="testimonials-section border-bottom">
            <div className="py-5">
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col xxl={9}>
                            <h1 className="display-5 fw-bold text-uppercase text-black">
                                TRUSTED BY OPERATORS
                            </h1>
                            <p className="fs-5">
                                Pest control businesses across the country rely on our software to gain actionable insights and optimize their operations. Here’s what some of them have to say.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container className="d-flex align-items-center justify-content-center mb-5">
                <Button className="PrevArrow testimonials-arrow  me-lg-3 me-4 ">
                    <FaArrowLeft />
                </Button>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    breakpoints={{
                        576: {
                            slidesPerView: 1
                        },
                        768: { slidesPerView: 2 },
                        1200: { slidesPerView: 2 },
                    }}
                    speed={1000}
                    modules={[Navigation, Autoplay]}
                    autoplay
                    navigation={{ nextEl: ".NextArrow", prevEl: ".PrevArrow" }}
                    className="flex-grow-1"
                >
                    {testimonials.map((testimonial) => (
                        <SwiperSlide key={testimonial.id}>
                            <TestimonialCard
                                image={testimonial.image}
                                description={testimonial.description}
                                name={testimonial.name}
                                domain={testimonial.domain}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Button className="NextArrow testimonials-arrow ms-lg-3 ms-2">
                    <FaArrowRight />
                </Button>
            </Container>
        </div>
    );
};

export default Testimonials;
