import React from 'react'
import { Col, Form, Dropdown, Row, Image, Button } from 'react-bootstrap';
import FilterIcon from 'assets/svg/filter.svg'
const Filter = ({ filters, setFilter, activeFilter }) => {
    return (
        <Row className='py-md-4 py-2'>
            <Col xs={12}>
                <Form className='d-flex gap-3 align-items-center justify-content-between'>
                    <Dropdown>
                        <Dropdown.Toggle className='rounded-3 filter-sort e-caret-hide px-3'>
                            Sort By <Image src={FilterIcon} alt="F" className='ps-2'></Image>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='py-0'>
                            {filters.map((filter, index) => {
                                if (index < 2) {
                                    return <Dropdown.Item className='border-top text-center' key={index} onClick={() => setFilter(filter)}>{`${filter} Days`}</Dropdown.Item>;
                                } else {
                                    return <Dropdown.Item className='border-top text-center' key={index} onClick={() => setFilter(filter)}>{`${filter} Months`}</Dropdown.Item>;
                                }
                            })}

                        </Dropdown.Menu>
                    </Dropdown>

                    <div className='d-flex  d-sm-block d-none'>
                        {filters.map((filter, index) => {
                            const isDayFilter = index < 2;
                            const filterLabel = isDayFilter ? `${filter} Days` : `${filter} Months`;
                            const buttonClass = `rounded-4 ms-2 px-4 py-2  btn-custom-hover  ${filter === activeFilter ? "btn-custom" : "btn-secondary"}`;

                            return (
                                <Button
                                    className={buttonClass}
                                    key={index}
                                    onClick={() => setFilter(filter)}
                                >
                                    {filterLabel}
                                </Button>
                            );
                        })}
                    </div>
                </Form>
            </Col>
        </Row>
    )
}

export default Filter
