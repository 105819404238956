import React from 'react';
import { Table } from 'react-bootstrap';

const DashboardTable = ({ tableBody, tableHeadings }) => {
    console.log("Table Body", tableBody)
    return (
        <div className="shadow p-3 rounded-3">
            <Table hover responsive>
                <thead>
                    <tr>
                        {tableHeadings?.map((th, idx) => (
                            <th style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }} key={idx}>{th}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableBody?.map((row, i) => (
                        <tr key={i}>
                            <td style={{ color: "#718096" }}>{row[0]}</td>
                            <td style={{ color: "#718096" }}>{row[1]}</td>
                            <td style={{ color: "#718096" }}>{row[2]}</td>
                            <td style={{ color: "#718096" }}>{row[3]}</td>
                            <td style={{ color: "#718096" }}>{row[4]}</td>
                            <td style={{ color: "#718096" }}>{row[5]}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DashboardTable;
