import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { FaArrowRightLong } from "react-icons/fa6";

const ReadyToJoin = () => {
    return (
        <div style={{ backgroundColor: "#F5F7FA" }}>
            <Container className='text-center py-5'>


                <h1 className='display-5 mb-lg-4 '>Ready to join them?</h1>
                <h1 className='display-5 mb-lg-4 '>Schedule a meeting with us today!</h1>
                <Button href=' https://calendly.com/pestinsights-info/30min' target='_blank' className='py-3 px-md-5 px-3  text-white bg-primary-ltr  border-0' >Schedule a Meeting <span className="ps-2"><FaArrowRightLong />
                </span> </Button>
            </Container>

        </div>
    )
}

export default ReadyToJoin
