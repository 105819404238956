import BenefitImg from 'assets/BenefitImg.png'
import Content6by6 from 'components/shared/Content6by6'
const Benefits = () => {
    const benefits = [
        { id: 0, heading: "PERFORMANCE TRACKING", text: "Understanding technician performance metrics such as time on site, on-time rate, and production value helps operators identify high performers and those needing improvement, leading to more efficient and effective service delivery." },
        { id: 1, heading: "ENHANCED CUSTOMER SATISFACTION", text: "By monitoring re-service rates and customer feedback, operators can ensure that technicians provide consistent, high-quality service, directly impacting customer retention and satisfaction." },
        { id: 2, heading: "OPERATIONAL EFFICIENCY", text: "Analyzing driving mileage and route efficiency allows operators to optimize scheduling and reduce fuel costs, leading to overall better resource management and reduced operational expenses." }
    ]
    return (

        <Content6by6 fs={"fs-5"} content={benefits} h1={""} h2={"BENEFITS"} order1={1} order2={2} image={BenefitImg} />
    )
}

export default Benefits
