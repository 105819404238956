import CustomChart from 'components/charts/Line';
import Filter from 'components/shared/Filter';
import DashboardTable from 'components/shared/Table';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const ReService = () => {

    const [filter, setFilter] = useState(90)

    const tableHeadings = [
        "Location",
        "Num Appt",
        "Avg. Tech Time on Site",
        "Avg. Production Value",
        "Total Production",
        "Reservice Rate"
    ];

    const tableBody = [
        {
            location: "Scranton",
            numAppt: 120,
            avgTechTime: "1.5 hours",
            avgProductionValue: "$2,000",
            totalProduction: "$240,000",
            reserviceRate: "5%"
        },
        {
            location: "Green Earth",
            numAppt: 95,
            avgTechTime: "2 hours",
            avgProductionValue: "$1,800",
            totalProduction: "$171,000",
            reserviceRate: "7%"
        },
        {
            location: "Health Plus",
            numAppt: 110,
            avgTechTime: "1.2 hours",
            avgProductionValue: "$2,200",
            totalProduction: "$242,000",
            reserviceRate: "4%"
        },
        {
            location: "EduConnect",
            numAppt: 85,
            avgTechTime: "1.8 hours",
            avgProductionValue: "$1,600",
            totalProduction: "$136,000",
            reserviceRate: "6%"
        },
        {
            location: "FinCorp",
            numAppt: 75,
            avgTechTime: "2.2 hours",
            avgProductionValue: "$2,400",
            totalProduction: "$180,000",
            reserviceRate: "8%"
        },
        {
            location: "Tech Innovators",
            numAppt: 130,
            avgTechTime: "1.6 hours",
            avgProductionValue: "$2,300",
            totalProduction: "$299,000",
            reserviceRate: "5%"
        },
        {
            location: "Bright Future",
            numAppt: 140,
            avgTechTime: "1.4 hours",
            avgProductionValue: "$2,100",
            totalProduction: "$294,000",
            reserviceRate: "4%"
        },
        {
            location: "Eco Solutions",
            numAppt: 105,
            avgTechTime: "2 hours",
            avgProductionValue: "$1,900",
            totalProduction: "$199,500",
            reserviceRate: "6%"
        },
        {
            location: "NextGen",
            numAppt: 100,
            avgTechTime: "1.7 hours",
            avgProductionValue: "$2,000",
            totalProduction: "$200,000",
            reserviceRate: "7%"
        },
        {
            location: "HealthCare Pro",
            numAppt: 115,
            avgTechTime: "1.3 hours",
            avgProductionValue: "$2,250",
            totalProduction: "$258,750",
            reserviceRate: "3%"
        }
    ];
    const filters = [30
        , 90
        , 6
        , 12]
    const GetResult = async () => {
        console.log("result search")
    }

    useEffect(() => {
        GetResult()
    }, [filter])

    const data = [
        { name: 'Jan', uv: 1 },
        { name: 'Feb', uv: 2 },
        { name: 'Mar', uv: 3 },
        { name: 'Apr', uv: 4 },
        { name: 'May', uv: 5 },
        { name: 'Jun', uv: 0 },
        { name: 'Jul', uv: 7 },
        { name: 'Aug', uv: 8 },
        { name: 'Sep', uv: 29 },
        { name: 'Oct', uv: 10 },
        { name: 'Nov', uv: 12 },
        { name: 'Dec', uv: 14 },
    ];

    const color1 = "#1a93ea"
    const color2 = "#1a93ea"
    return (
        <Container className='mb-5' fluid>
            <h1>Re Service Breakdown</h1>
            <Filter filters={filters} setFilter={setFilter} activeFilter={filter} />

            <Row className='py-4 gap-xl-0 gap-4'>
                <Col xs={12}>
                    <section className='p-4 pb-0 rounded-4'>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Avg. Production Value</h5>
                        </div>


                        <CustomChart
                            data={data}
                            dataKeys={['uv']}
                            xAxisKey="name"
                            defs={true}
                            marginleft={-30}
                            height={400}
                            strokeWidth={-1}
                            defColors={[color1, color2]}
                            vr={false}
                            colors={{
                                stroke: [color1, color2],
                                fill: [color1, color2],
                            }}
                            legend={true}
                            legendPosition={{ left: 0, top: 0 }}
                            grid={false}
                        />



                    </section>
                </Col>


            </Row >
            <h5 className='my-4'>Re Service Breakdown Details</h5>
            <DashboardTable tableHeadings={tableHeadings} tableBody={tableBody} />


        </Container >
    );
};

export default ReService;
