import React from 'react';
import { Card, Row, Col, Badge } from 'react-bootstrap';

const CompanyCardDetail = ({ company }) => {
    return (
        <>
            <h4 style={{ color: '#4E4E4E' }} className="my-4">
                Company Info
            </h4>
            <Card className="mb-4 boder-0 shadow" style={{ border: 'unset' }}>
                <Card.Body>
                    <Card className="mb-2 shadow rounded border-0" style={{ background: 'rgb(253 253 253)' }}>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <Card.Text as="h3" className="fw-bold mb-0" style={{ color: '#4E4E4E' }} >
                                    {company?.name}
                                </Card.Text>
                                <Badge
                                    pill
                                    bg={company?.isActive ? 'success' : 'danger'}
                                    // className="px-3 py-2"
                                    style={{ fontSize: '1rem' }}
                                >
                                    {company?.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </div>
                        </Card.Body>
                    </Card>
                    <Row className='p-4'>
                        <Col md={6} className="">
                            <Card.Text as='h4' className="fw-bold text-secondary">Company Information</Card.Text>
                            <div className='p-2'>
                                <Card.Text className='pb-0 mb-2 '>
                                    <strong className='info-head'>Address:</strong> <span className='text-secondary'> {company?.address || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className=' info-head'>Country:</strong><span className='text-secondary'> {company?.country || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className=' info-head'>Phone:</strong><span className='text-secondary'> {company?.phone || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text>
                                    <strong className='info-head'>Max Users:</strong><span className='text-secondary'> {company?.maxUser || 'N/A'}</span>
                                </Card.Text>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Card.Text as='h4' className="fw-bold text-secondary">Admin Information</Card.Text>
                            <div className='p-2'>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className='info-head'>Email:</strong><span className='text-secondary'> {company?.admin?.email || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className='info-head'>Full Name:</strong> <span className='text-secondary'>{company?.admin?.firstname || 'N/A'} {company?.admin?.lastname || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text>
                                    <strong className='info-head'>Phone:</strong><span className='text-secondary'> {company?.admin?.phone || 'N/A'}</span>
                                </Card.Text>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default CompanyCardDetail;
