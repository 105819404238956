import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, Row, Col } from 'react-bootstrap';

const CompanyDetailSkeleton = () => {
    return (
        <>
            <h4 style={{ color: '#4E4E4E' }} className="my-4">
                <Skeleton width={180} />
            </h4>
            <Card className="mb-4 border-0 shadow">
                <Card.Body>
                    {/* Skeleton for the Company Name and Status */}
                    <Card className="mb-2 shadow rounded border-0" style={{ background: 'rgb(253 253 253)' }}>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <Skeleton width={200} height={30} />
                                <Skeleton width={80} height={25} />
                            </div>
                        </Card.Body>
                    </Card>

                    {/* Skeleton for Company and Admin Information */}
                    <Row className='p-4'>
                        {/* Company Information Skeleton */}
                        <Col md={6}>
                            <Skeleton width={150} height={25} className="mb-3" />
                            <div className="p-2">
                                <Skeleton width="100%" height={20} className="mb-2" />
                                <Skeleton width="90%" height={20} className="mb-2" />
                                <Skeleton width="80%" height={20} className="mb-2" />
                                <Skeleton width="70%" height={20} />
                            </div>
                        </Col>

                        {/* Admin Information Skeleton */}
                        <Col md={6}>
                            <Skeleton width={150} height={25} className="mb-3" />
                            <div className="p-2">
                                <Skeleton width="100%" height={20} className="mb-2" />
                                <Skeleton width="90%" height={20} className="mb-2" />
                                <Skeleton width="80%" height={20} />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default CompanyDetailSkeleton;
