import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Alert } from 'react-bootstrap';
import { CountrySelect } from 'react-country-state-city';
import { useLocation } from 'react-router-dom';
import { adduser } from 'services/admin/admin.service';
import CreateModal from './Popup';
import ConfirmModal from 'components/modals/ConfirmModal';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddUser = ({ show, close, companyId, reCall }) => {
    const location = useLocation();
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState({});
    const [errorResponse, setErrorResponse] = useState(null);
    const { userData } = location.state || {};
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        country: '',
        useremail: '',
        phone: '',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userData) {
            setFormData({
                firstname: userData?.firstname,
                lastname: userData?.lastname,
                useremail: userData?.useremail,
                phone: userData?.phone,
            });
        }
    }, [userData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setError((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.firstname) {
            formErrors.firstname = "First name is required";
        }
        if (!formData.lastname) {
            formErrors.lastname = "Last name is required";
        }
        if (!formData.useremail) {
            formErrors.useremail = "Email address is required";
        }

        setError(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleFormSubmit = async () => {
        if (validateForm()) {
            setModalShow(true);
        }
    };

    const handleCreate = async () => {
        setLoading(true);
        setErrorResponse(null);
        setModalShow(false);
        try {
            await adduser(companyId, formData);
            setFormData({
                firstname: '',
                lastname: '',
                country: '',
                useremail: '',
                phone: '',
            });
            reCall();
            close();
        } catch (err) {
            setErrorResponse(err?.response?.data?.error || 'Network Error');
            console.error('Error:', err);
        } finally {
            setLoading(false);
            setModalShow(false);
        }
    };

    const handleCancel = () => {
        close();
        setError({});
        setFormData({
            firstname: '',
            lastname: '',
            country: '',
            useremail: '',
            phone: '',
        });
    };

    return (
        <Modal show={show} onHide={close} centered size="lg" className='py-0 my-0'>
            <Modal.Header className='border-0' closeButton />
            <Container>
                <Form className='pb-3 px-4'>
                    <h3 className='text-center mb-4 text-box' style={{ color: "var(--primary-color)" }}>Add User</h3>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId='formFirstName'>
                                <Form.Label className='text-custom my-2'>First Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type='text'
                                    name='firstname'
                                    value={formData.firstname}
                                    onChange={handleInputChange}
                                    placeholder='Enter first name'
                                    required
                                    isInvalid={!!error.firstname}
                                />
                                <Form.Control.Feedback type="invalid">{error.firstname}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId='formLastName'>
                                <Form.Label className='text-custom my-2'>Last Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type='text'
                                    name='lastname'
                                    value={formData.lastname}
                                    onChange={handleInputChange}
                                    placeholder='Enter last name'
                                    required
                                    isInvalid={!!error.lastname}
                                />
                                <Form.Control.Feedback type="invalid">{error.lastname}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col md={6} xs={12}>
                            <Form.Group controlId='formEmail'>
                                <Form.Label className='text-custom my-2'>Email Address*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    className='py-2'
                                    type="email"
                                    placeholder="Enter your email"
                                    name="useremail"
                                    value={formData.useremail}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!error.useremail}
                                />
                                <Form.Control.Feedback type="invalid">{error.useremail}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                            <Form.Group className='custome-select-country'>
                                <Form.Label className='text-custom my-2'>Country</Form.Label>
                                <CountrySelect
                                    inputClassName="py-1 border-0"
                                    placeHolder="Select Country"
                                    value={formData.country}
                                    onChange={(country) => setFormData({ ...formData, country: country.name })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label className='text-custom my-2'>Phone Number</Form.Label>
                                <PhoneInput
                                    inputClass='custome-phone py-4'
                                    country={'all'}
                                    placeholder='Enter your phone number'
                                    value={formData.phone}
                                    onChange={(phone) => setFormData({ ...formData, phone: phone })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {errorResponse && <Alert variant="danger" className="text-center">{errorResponse}</Alert>}
                    <div className="d-flex justify-content-between pt-2">
                        <Button onClick={handleCancel} className='bg-secondary border-0 px-4 py-2' disabled={loading}>Cancel</Button>
                        <Button type="button" className='bg-primary-ltr border-0 px-4 py-2 submit-btn-hover' onClick={handleFormSubmit} disabled={loading}>
                            {loading ? 'Creating...' : 'Create'}
                        </Button>
                    </div>
                </Form>

                <CreateModal
                    loading={loading}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onCreate={handleCreate}
                />
                <ConfirmModal
                    text='Do you really want to delete this user?'
                    show={modalShow}
                    handleClose={() => setModalShow(false)}
                    handleConfirm={handleCreate}
                />
            </Container>
        </Modal>
    );
};

export default AddUser;
