import React from 'react'

const NotFound = () => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{
            height: "90vh", background: "var(--bg-ltr)"
        }}>
            <h1 className="display-1 text-white">404</h1>
        </div>
    )
}

export default NotFound
