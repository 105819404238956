import { Button, Modal } from 'react-bootstrap';

const CreateModal = ({ show, onHide, onCreate, loading }) => {

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header className='border-0' closeButton>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center'>Are you sure you want to create it?</p>
            </Modal.Body>
            <Modal.Footer className='justify-content-center gap-3 mb-4 border-0' >
                <Button onClick={onHide} className='bg-secondary border-0 px-5 py-2'>No</Button>

                <Button onClick={onCreate} type="submit" className='bg-primary-ltr border-0 px-5 py-2' disabled={loading}>
                    {loading ? 'Creating...' : 'Yes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateModal;