import { GetApiData } from "utils/http-client";
export const addCompany = function (data) {
  return GetApiData(`/company/create`, "POST", data, true);
};
export const adduser = function (companyId, data) {
  return GetApiData(`/user/create/${companyId}`, "POST", data, true);
};
export const DeleteUser = function (userId, companyId) {
  return GetApiData(`/user/delete/${userId}/${companyId}`, "DELETE", null, true);
};
export const GetWaitingUser = function () {
  return GetApiData(`/waiting-user/`, "GET", null, true);
};
export const GetCompanyUser = function (companyId) {
  return GetApiData(`/user/all/${companyId}`, "GET", null, true);
};
export const GetCompany = function () {
  return GetApiData(`/company/`, "GET", null, true);
};
export const GetSingleCompany = async (companyId) => {
  return await GetApiData(`/company/${companyId}`, "GET", null, true);
};

export const UpdateCompanyStatus = async (companyId, newStatus) => {
  return await GetApiData(
    `/company/status/${companyId}`,
    "PUT",
    { newStatus },
    true
  );
};
export const UpdateCompanyDetail = async (companyId, data) => {
  return await GetApiData(`/company/update/${companyId}`, "PUT", data, true);
};
export const deleteCompany = async (companyId) => {
  return await GetApiData(`/company/${companyId}`, "DELETE", null, true);
};
