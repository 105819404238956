import file from 'assets/svg/file.svg';
import CustomBarChart from 'components/charts/BarChart';
import CustomChart from 'components/charts/Line';
import Filter from 'components/shared/Filter';
import PageHeading from 'components/shared/PageHeading';
import DashboardTable from 'components/shared/Table';
import { useEffect, useState } from 'react';
import { Col, Container, Dropdown, Image, Row } from 'react-bootstrap';
const Dashboard = () => {
    // const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
    const user = JSON.parse(localStorage.getItem("user")) || {}
    const [filter, setFilter] = useState(30)

    const tableHeadings = ["Location", "Num Appt", "Avg. Tech Time on Site  ", "Avg. Production Value", "Total Production", "Reservice Rate"];

    const tableBody = [
        ["Tech Innovators", 12, "3 hrs", "$4,500", "$54,000", "5%"],
        ["Green Earth", 8, "2.5 hrs", "$3,800", "$30,400", "2%"],
        ["Health Plus", 15, "4 hrs", "$5,000", "$75,000", "7%"],
        ["EduConnect", 10, "3.5 hrs", "$4,200", "$42,000", "4%"],
        ["FinCorp", 6, "2 hrs", "$3,000", "$18,000", "6%"]
    ];
    const filters = [30
        , 90
        , 6
        , 12]
    const cardData = [
        { id: 1, title: "Num Apts", value: "5627", percentage: "12987981%", icon: file },
        { id: 2, title: "Num Users", value: "4580", percentage: "987654%", icon: file },
        { id: 3, title: "Revenue", value: "$12,340", percentage: "15%", icon: file },
        { id: 4, title: "New Leads", value: "923", percentage: "20%", icon: file },
        { id: 5, title: "Conversions", value: "125", percentage: "30%", icon: file },
    ];
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    useEffect(() => {


        GetResult()
    }, [filter])
    const GetResult = async () => {
        console.log("result search")
    }

    const color1 = "#3182CE"
    const color2 = "#1CC76D"
    const data = [
        { name: 'Jan', uv: 40, pv: 24 },
        { name: 'Feb', uv: 30, pv: 13.98 },
        { name: 'Mar', uv: 20, pv: 98 },
        { name: 'Apr', uv: 27.8, pv: 39.08 },
        { name: 'May', uv: 18.9, pv: 48 },
        { name: 'Jun', uv: 23.9, pv: 38 },
        { name: 'Jul', uv: 34.9, pv: 43 },
    ];


    const [year, setYear] = useState(2024);
    const years = [2023, 2022, 2021, 2020];

    return (
        <Container className='mb-5' fluid>
            <h4>Hello {`${capitalizeFirstLetter(user.firstname)} ${capitalizeFirstLetter(user.lastname)}`}

                👋🏼,
            </h4>
            <Filter filters={filters} setFilter={setFilter} activeFilter={filter} />
            <Row className=" gap-2 ">
                {cardData.map(card => (
                    <Col key={card.id} className="mb-4 shadow rounded-4 p-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="mb-0 fs-12">{card.title}</p>
                                <h6 className="mb-0 small fw-semibold text-black">{card.value}</h6>
                            </div>
                            <div style={{ width: "30px", height: "30px" }}>
                                <Image src={card.icon} fluid
                                    alt="icon" />
                            </div>
                        </div>
                        <p className="mb-0 fs-12 mt-3">
                            <span className="primary-color">{card.percentage}</span> Since Last Month
                        </p>
                    </Col>
                ))}
            </Row>
            <Row className='py-4 gap-xl-0 gap-4'>
                <Col xl={8} xs={12}>
                    <section style={{
                        boxShadow: "0px 5px 14px 0px #0000000D"
                    }} className='border border-dark p-4 rounded-4'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <PageHeading fontSize="18px" color="#083932" heading={"Production Over Time"} />
                                <h6 className='small' style={{ color: "#083932" }}><span style={{ color: "#1CC76D" }}>(+5) more</span> in 2024</h6>
                            </div>
                            <div className='d-flex gap-2'>
                                <Dropdown>
                                    <Dropdown.Toggle className='rounded-3 filter-sort e-caret-white bg-black text-white px-3'>
                                        {year}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='py-0'>
                                        {years.map((year, index) => (
                                            <Dropdown.Item className='border-top text-center' key={index} onClick={() => setYear(year)}>
                                                {`${year} `}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <CustomChart
                            data={data}
                            dataKeys={['uv', 'pv']}
                            xAxisKey="name"
                            defs={true}
                            height={405}
                            marginleft={-30}
                            strokeWidth={4}
                            defColors={[color1, color2]}
                            vr={false}
                            colors={{
                                stroke: [color1, color2],
                                fill: [color1, color2],
                            }}
                            legend={false}
                            grid={true}
                        />
                    </section>

                </Col>
                <Col xl={4} xs={12}>
                    <CustomBarChart />

                </Col>

            </Row>
            <h5 className='my-4'>Overview</h5>
            <DashboardTable tableHeadings={tableHeadings} tableBody={tableBody} />

        </Container >
    );
};

export default Dashboard;
