import SignUp from 'assets/Sign Up.png'
import Content6by6 from 'components/shared/Content6by6'
const Works = () => {
    const works = [


        {
            id: 0, heading: "1. Sign Up and Connect Your Data", text: "Get started by signing up and connecting your existing systems to our platform. We integrate seamlessly with your current tools, pulling in all the essential data you need."
        },
        {
            id: 1, heading: "2. Analyze Technician Performance", text: "Once your data is synced, our software automatically analyzes key metrics like time on site, production value, route efficiency, and more. You’ll get a clear view of each technician’s performance and areas for improvement."
        },
        { id: 2, heading: "3. Optimize and Grow", text: "With real-time insights at your fingertips, you can make data-driven decisions to optimize your operations, enhance customer satisfaction, and ultimately grow your business. Regular reports and dashboards keep you informed and ahead of the competition." }
    ]
    return (

        <Content6by6 fs={"fs-5"} content={works} h1={"HOW IT WORKS"} h2={""} order1={2} order2={1} image={SignUp} />
    )
}

export default Works
