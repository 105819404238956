import { Image, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

import logo from "assets/PestInsightsLogo_rectangle.png";
import { sidebarMenus } from 'components/shared/SidebarMenus';
import { GetUserRoles } from 'utils/auth.utils';



const Sidebar = () => {

  const UserRole = GetUserRoles()
  const isVisible = (visibleOn) => {
    return visibleOn.some(role => role === UserRole);
  };
  return (
    <div className='border-end border-3' style={{ minWidth: '280px', minHeight: '100%' }}>
      <Nav className="flex-column">
        <div className=' px-4  d-flex align-items-center'>
          <Link className="d-none d-lg-block pb-2">
            <Image
              src={logo}
              alt='Logo'
              className='w-100'
              style={{ height: '60px', width: "160px" }}
            />
          </Link>
        </div>
        <div>
          {sidebarMenus.map((link) => (
            isVisible(link.visibleOn) && (

              <NavLink
                className="py-3 px-4 d-flex align-items-center sidebar-link"
                key={link.path}
                to={link.path}
                style={({ isActive }) => ({
                  color: isActive ? 'var(--primary-color)' : 'black',
                  fontWeight: isActive ? 'bold' : '',
                  background: isActive
                    ? '#dbfff0 '
                    : 'transparent',
                  borderTopRightRadius: '30px',
                  borderBottomRightRadius: '30px',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                })}

              >
                <div className='mb-1' style={{ marginRight: '10px' }}>
                  <Image src={link.icon} alt={link.label} />
                </div>
                {link.label}
              </NavLink>
            )))}
        </div>
      </Nav >
    </div >
  );
};

export default Sidebar;
