import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAuth } from 'utils/AuthContext';
import Skeleton from 'react-loading-skeleton';
import { changePassword, getUserDetail, profileUser, updateUserDetail } from 'services/UserManagement/setting.service';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
const Settings = () => {
  const [firstName, setFirstName] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [updatedPassword, setUpdatedPassword] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataload, setDataLoad] = useState(false);
  const fileInputRef = useRef(null);
  const { logout } = useAuth();


  const getUserDetails = async () => {
    try {
      const response = await getUserDetail();
      setEmail(response?.data?.data?.email || '');
      setFirstName(response?.data?.data?.firstname || '');
      setLastName(response?.data?.data?.lastname || '');
      setImageUrl(response?.data?.data?.image || '');
      setLoading(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setLoading(false);
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    e.target.value = null;
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setDataLoad(true);
    if (currentPassword === "" && updatedPassword === "") {
      const toastId = toast.loading('Updating User details');

      try {
        let imageUrlToUpdate = imageUrl;
        if (file) {
          const formData = new FormData();
          formData.append('attachments', file);
          const response = await profileUser(formData);
          console.log("Response pic", response.data.imageUrl)
          imageUrlToUpdate = response.data.imageUrl;
        }

        const updatedUser = {
          firstname: firstName,
          lastname: lastName,
          image: imageUrlToUpdate,
        };

        await updateUserDetail(updatedUser);

        const currentUser = JSON.parse(localStorage.getItem("user")) || {};
        const updatedUserinfo = {
          ...currentUser,
          firstname: firstName,
          lastname: lastName,
          image: imageUrl
        };

        localStorage.setItem("user", JSON.stringify(updatedUserinfo));
        window.dispatchEvent(new Event('user-update'));
        setDataLoad(false);
        toast.update(toastId, {
          render: 'User updated successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

        getUserDetails();
      } catch (error) {
        setDataLoad(false);
        toast.update(toastId, {
          render: error.response?.data?.error || 'An error occurred',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
  };


  const handlePasswordUpdate = async (event) => {
    event.preventDefault()

    const toastId = toast.loading('Updating Password');
    try {
      await changePassword({ currentPassword, newPassword: updatedPassword });
      toast.update(toastId, {
        render: 'Please login again to continue',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      setCurrentPassword('');
      setUpdatedPassword('');
      logout()

    } catch (error) {
      console.log("eerror", error)
      toast.update(toastId, {
        render: error?.response?.data?.message || 'An error occurred',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  if (loading) {
    return (
      <>
        <Card className='p-4 border-0 mt-4' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
          <Col xs={12} lg={3}>
            <Skeleton height={30} />
          </Col>
          <div className='mb-3 d-flex justify-content-center align-items-center' style={{ width: "150px", height: "150px", margin: "auto", position: 'relative', borderRadius: "60%", overflow: "hidden", border: "5px solid #ea4f50", boxShadow: "rgba(234, 79, 80, 0.4) 0px 7px 29px 0px" }}>
            <Skeleton circle={true} height={150} width={150} />
          </div>
          <Button
            variant="primary"
            style={{
              borderRadius: '20px',
              backgroundColor: '#de494b',
              borderColor: '#e04d4f',
              width: "100px",
              margin: "auto",
              marginTop: "-40px",
              zIndex: "999"
            }}
            disabled
          >
            <Skeleton width={30} />
          </Button>
          <Row className='mt-2'>
            <Col xs={12} lg={6}>
              <Skeleton height={40} />
            </Col>
            <Col xs={12} lg={6}>
              <Skeleton height={40} />
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button
              className='px-4 py-2'
              style={{
                borderRadius: "30px",
                background: "linear-gradient(90deg, #EC7677 0%, #DE494B 100%)",
                borderColor: "#e04d4f"
              }}
              disabled
            >
              <Skeleton width={80} />
            </Button>
          </div>
        </Card>
        <Card className='p-4 border-0 mt-4' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
          <Col xs={12} lg={3}>
            <Skeleton height={30} />
          </Col>
          <Row className='mt-2'>
            <Col xs={12} lg={6}>
              <Skeleton height={40} />
            </Col>
            <Col xs={12} lg={6}>
              <Skeleton height={40} />
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button
              className='px-4 py-2'
              style={{
                borderRadius: "30px",
                background: "linear-gradient(90deg, #EC7677 0%, #DE494B 100%)",
                borderColor: "#e04d4f"
              }}
              disabled
            >
              <Skeleton width={80} />
            </Button>
          </div>
        </Card>



      </>
    );
  }

  return (
    <>
      <Form noValidate onSubmit={handleFormSubmit}>
        <h1 className='mb-0' style={{ color: '#4E4E4E' }}>User Settings</h1>
        <Card className='p-4 border-0 mt-2 ' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
          <h4 style={{ color: '#4E4E4E' }} className='my-4'>
            User Details
          </h4>
          <div className='mb-3 d-flex justify-content-center align-items-center' style={{ width: "150px", height: "150px", margin: "auto", position: 'relative', borderRadius: "60%", overflow: "hidden", border: "5px solid #1cc76d", boxShadow: "rgba(28, 199, 109, 0.4) 0px 7px 29px 0px" }}>
            <Image
              src={imageUrl || 'https://via.placeholder.com/150'}
              className='w-100'
              alt="User Profile"
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleImageUpload}
            />
          </div>

          <Button
            variant="primary"
            onClick={handleUploadClick}
            style={{
              borderRadius: '20px',
              backgroundColor: '#1cc76d',
              borderColor: '#1cc76d',
              width: "100px",
              margin: "auto",
              marginTop: "-40px",
              zIndex: "999"
            }}
          >
            Upload
          </Button>

          <Row className='mt-2'>
            <div className='text-center text-muted fw-bold'>
              <p>{email}</p>
            </div>
            <Col xs={12} lg={6}>
              <Form.Group controlId='formName'>
                <Form.Label className='text-secondary my-2'>First Name</Form.Label>
                <Form.Control
                  size='lg'
                  type='text'
                  value={firstName || ''}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder='Enter your first name'
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group controlId='formName'>
                <Form.Label className='text-secondary my-2'>Last Name</Form.Label>
                <Form.Control
                  size='lg'
                  type='text'
                  value={lastName || ''}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder='Enter your Last name'
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-end mt-3">
            <Button
              className='px-4 py-2'
              style={{
                borderRadius: "30px",
                background: "linear-gradient(90deg, #1cc76d 0%, #007a48 100%)",
                borderColor: "#1cc76d"
              }}
              type="submit"
            >
              Save Changes
            </Button>
          </div>
        </Card>


      </Form>
      <Form onSubmit={
        handlePasswordUpdate
      }>
        <Card className='p-4 border-0 mt-4 ' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
          <h4 style={{ color: '#4E4E4E' }} className='my-4'>
            Change Password
          </h4>
          <Row className='mt-3'>
            <Col xs={12} lg={6}>
              <Form.Group controlId='formCurrentPassword' className='position-relative'>
                <Form.Label className='text-secondary my-2'>Current Password</Form.Label>
                <Form.Control
                  size='lg'
                  type={passwordVisible ? "text" : "password"}
                  // type='password'
                  value={currentPassword || ''}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder='Current password'
                />
                <div
                  className="position-absolute top-50 end-0 mx-2 pt-1 pe-2 bg-white"
                  style={{ cursor: 'pointer' }}
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group controlId='formNewPassword' className='position-relative'>
                <Form.Label className='text-secondary my-2'>New Password</Form.Label>
                <Form.Control
                  size='lg'
                  // type='password'
                  type={newPasswordVisible ? "text" : "password"}
                  value={updatedPassword || ''}
                  onChange={(e) => setUpdatedPassword(e.target.value)}
                  placeholder='Enter your new password'
                />
                <div
                  className="position-absolute top-50 end-0 mx-2 pt-1 pe-2 bg-white"
                  style={{ cursor: 'pointer' }}
                  onClick={toggleNewPasswordVisibility}
                >
                  {newPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button
              className='px-4 py-2'
              style={{
                borderRadius: "30px",
                background: "linear-gradient(90deg, #1cc76d 0%, #007a48 100%)",
                borderColor: "#1cc76d"
              }}
              type="submit"
            >
              Save Changes
            </Button>
          </div>
        </Card>
      </Form>
    </>
  );
};

export default Settings;

