import React from 'react';
import {
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Area,
    ResponsiveContainer,
} from 'recharts';

const CustomTooltip = ({ active, payload, label, tooltipStyles, valueFormatter }) => {
    if (active && payload && payload.length) {
        return (
            <div style={tooltipStyles.container}>
                <p style={tooltipStyles.label}>{`${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={`tooltip-item-${index}`} style={{ ...tooltipStyles.value, color: entry.color }}>
                        {`${entry.name}: ${valueFormatter ? valueFormatter(entry.value) : entry.value}`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

const CustomChart = ({
    data,
    dataKeys,
    hr,
    vr,
    strokeWidth,
    xAxisKey,
    marginleft,
    height,
    colors,
    legend,
    legendPosition,
    yAxisFormatter,
    valueFormatter,
    grid,
    defs,
    defColors
}) => {

    const defaultTooltipStyles = {
        container: {
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
        },
        label: {
            margin: 0,
            fontWeight: 'bold',
        },
        value: {
            margin: 0,
        },
    };

    return (
        <ResponsiveContainer width={"100%"} height={height || 400}>
            <AreaChart data={data} margin={{ top: 20, right: 0, left: marginleft || 20, bottom: 0 }}
            >
                {grid && (
                    <CartesianGrid
                        strokeDasharray="3 3"
                        horizontal={hr !== undefined ? hr : true}
                        vertical={vr !== undefined ? vr : true}
                    />
                )}

                {defs && (
                    <defs>
                        <linearGradient id={dataKeys[0]} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={defColors?.[0]} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={defColors?.[0]} stopOpacity={0.1} />
                        </linearGradient>
                        <linearGradient id={dataKeys[1]} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={defColors?.[1]} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={defColors?.[1]} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                )}

                <XAxis dataKey={xAxisKey} stroke="#AEAEAE" />
                <YAxis stroke="#AEAEAE" tickFormatter={yAxisFormatter} domain={['auto', 'auto']} />
                <Tooltip
                    content={
                        <CustomTooltip
                            tooltipStyles={defaultTooltipStyles}
                            valueFormatter={valueFormatter}
                        />
                    }
                />

                {legend && (
                    <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="left"
                        formatter={(value, entry) => {
                            const { color } = entry;
                            return (
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '12px',
                                            height: '12px',
                                            borderRadius: '50%',
                                            backgroundColor: color,
                                            marginRight: '10px',
                                        }}
                                    ></span>
                                    <span>{value}</span>
                                </span>
                            );
                        }}
                        wrapperStyle={{ position: 'absolute', ...legendPosition }}
                    />

                )}

                {dataKeys.map((key, index) => (
                    <Area
                        key={key}
                        type="monotone"
                        dataKey={key}
                        stroke={colors.stroke[index] || colors.stroke}

                        fill={
                            defs === true
                                ? `url(#${key})` : "transparent"}
                        strokeWidth={strokeWidth || 3}

                    />
                ))}
            </AreaChart>
        </ResponsiveContainer >
    );
};

export default CustomChart;
