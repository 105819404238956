import { sidebarMenus } from 'components/shared/SidebarMenus'
import { Image, Offcanvas } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { GetUserRoles } from 'utils/auth.utils'
const OffCanvas = ({ showSidebar, handleCloseSidebar }) => {
    const UserRole = GetUserRoles()
    const isVisible = (visibleOn) => {
        return visibleOn.some(role => role === UserRole);
    };
    return (
        <>
            <Offcanvas style={{ width: "300px" }} show={showSidebar} onHide={handleCloseSidebar} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='fw-semibold' style={{ color: "var(--primary-color)" }}>Navigation</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    {sidebarMenus.map((link) => (
                        isVisible(link.visibleOn) && (

                            <NavLink
                                className="py-3  px-4 d-flex align-items-center"
                                key={link.path}
                                to={link.path}
                                style={({ isActive }) => ({
                                    color: isActive ? 'var(--primary-color)' : 'black',
                                    fontWeight: isActive ? 'bold' : '',
                                    background: isActive
                                        ? '#dbfff0 '
                                        : 'transparent',
                                    borderTopRightRadius: '30px',
                                    borderBottomRightRadius: '30px',
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                })}

                            >
                                <div className='mb-1' style={{ marginRight: '10px' }}>
                                    <Image src={link.icon} alt={link.label} />
                                </div>
                                {link.label}
                            </NavLink>
                        )))}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OffCanvas