import PageHeading from 'components/shared/PageHeading';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

const data = [
    { month: 'Jul', avgTime: 10 },
    { month: 'Aug', avgTime: 20 },
    { month: 'Sep', avgTime: 30 },
    { month: 'Oct', avgTime: 40 },
    { month: 'Nov', avgTime: 50 },
    { month: 'Dec', avgTime: 60 },
];

const CustomBarChart = () => {
    return (
        <section style={{
            boxShadow: "0px 5px 14px 0px #0000000D"
        }} className='border border-dark  p-4 rounded-4'>
            <div className='pb-3'>
                <h6 className='text-secondary mb-0 fs-12'>PERFORMANCE</h6>
                <PageHeading fontSize={"18px"} color={"#2D3748"} heading={"Avg. Time On Site"} />
            </div>

            <ResponsiveContainer width="100%" height={400} >
                <BarChart data={data} margin={{ left: -30, top: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="month" />
                    <YAxis ticks={[0, 20, 40, 60]} />
                    <Tooltip />
                    <Bar

                        dataKey="avgTime"
                        fill="green"
                        strokeWidth={2}
                        radius={[10, 10, 0, 0]}
                        barSize={20}
                    />
                </BarChart>
            </ResponsiveContainer>
        </section>
    );
};

export default CustomBarChart;
