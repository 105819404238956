import UsersTable from "components/adminTable/UsersTable";
import CompanyDetailSkeleton from "components/Loaders/CompanyDetail";
import { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GetCompanyUser, GetSingleCompany, GetWaitingUser } from "services/admin/admin.service";
import AddUser from "./AddUser";
import CompanyCardDetail from "components/CompanyCard.Detail";
import { GetUserRoles } from "utils/auth.utils";
import { getUserDetail } from "services/UserManagement/setting.service";

const CompanyDetails = () => {
    const { companyId } = useParams();
    const [id, setId] = useState(null);
    const UserRole = GetUserRoles();
    const [company, setCompany] = useState(null);
    const [users, setUsers] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const companyUser = async () => {
        let response;
        try {
            const companyToFetch = companyId || id;
            if (companyToFetch) {
                response = await GetCompanyUser(companyToFetch);
                console.log("Waiting", response?.data?.data);
                setUsers(response?.data?.data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const GetCompany = async () => {
        let response;
        try {
            const companyToFetch = companyId || id;
            if (companyToFetch) {
                response = await GetSingleCompany(companyToFetch);
                setCompany(response?.data?.data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getUserDetails = async () => {
        try {
            const response = await getUserDetail();
            console.log("Response user id", response?.data?.data?.companyId);
            setId(response?.data?.data?.companyId); // Set the id in state
            setLoading(false);
            companyUser(); // Re-call after id is set
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (companyId) {
            GetCompany();
            companyUser();
        } else {
            getUserDetails();
        }
    }, [companyId, id]); // Add id as a dependency to re-call when it is set

    const FormClose = () => setShowAddUserModal(false);

    return (
        <Container fluid>
            <div className="mt-4">
                {UserRole === "superAdmin" && (
                    <div>
                        {loading ? (
                            <CompanyDetailSkeleton />
                        ) : (
                            <CompanyCardDetail company={company} />
                        )}
                    </div>
                )}
                {!loading && (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 style={{ color: '#4E4E4E' }} className="mt-4">
                            Company Users
                        </h4>
                        <Button
                            className="btn-custom px-3 fw-bold d-flex align-items-center"
                            onClick={() => setShowAddUserModal(true)}
                            style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
                        >
                            <span className="me-2">&#43;</span>
                            Add User
                        </Button>
                    </div>
                )}
                <UsersTable tableBody={users} loading={loading} />
                <AddUser
                    reCall={companyUser}
                    companyId={companyId || id}
                    show={showAddUserModal}
                    close={FormClose}
                />
            </div>
        </Container>
    );
};

export default CompanyDetails;
