import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import OffCanvas from './OffCanvas';
import SidebarHeader from './SidebarHeader';


const DashboardLayout = () => {
    const [showSidebar, setShowSidebar] = useState(false);

    const handleShowSidebar = () => setShowSidebar(true);
    const handleCloseSidebar = () => setShowSidebar(false);

    return (
        // <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        //     <div style={{ display: 'flex', flex: 1 }}>
        //         <div className="d-none d-lg-block">
        //             <Sidebar />
        //         </div>

        //         <main>
        //             <main className='px-3 py-md-2  w-100' >
        //                 <SidebarHeader onLogoClick={handleShowSidebar} />
        //             </main>
        //             <main className='px-3 py-md-2 main-sidebar' style={{ overflow: 'auto', height: "100vh" }}>
        //                 <Outlet />
        //             </main>
        //         </main>
        //     </div>

        //     <OffCanvas showSidebar={showSidebar} handleCloseSidebar={handleCloseSidebar} />
        // </div>
        <div className='d-flex flex-column min-height-vh'>
            <div className='d-lg-flex' >
                <div className="d-none d-lg-block">
                    <Sidebar />
                </div>

                <div className='d-flex flex-column' style={{ flex: 1 }}>
                    <div className='px-3 py-md-2'>
                        <SidebarHeader onLogoClick={handleShowSidebar} />
                    </div>

                    <div className='px-3 py-md-2' style={{ overflow: 'auto', height: '90vh' }}>
                        <Outlet />
                    </div>
                </div>
            </div>

            <OffCanvas showSidebar={showSidebar} handleCloseSidebar={handleCloseSidebar} />
        </div>

    );
}

export default DashboardLayout;
