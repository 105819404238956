import { useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Forgot } from 'services/authentication/authentication.service'; // Assuming this service is used for login

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess(false);
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setError('Please enter a valid email address');
            setLoading(false);
            return;
        }
        try {
            const response = await Forgot({ email });
            const user = response?.data?.data;
            console.log("Response:", user);
            toast.success("OTP sent to this email!");
            localStorage.setItem("email", email);
            setSuccess(true);
            setTimeout(() => navigate('/otp'), 2000);
            setLoading(false);
        } catch (err) {
            console.error('Error during request:', err);
            setError(err?.response?.data?.error || 'An error occurred, please try again later.');
            setLoading(false);

        }
    };

    return (
        <div className='signin d-flex justify-content-center align-items-center min-vh-100'>
            <Row className='w-100 justify-content-center'>
                <Col lg={4} md={6} sm={8} xs={12}>
                    <div className='bg-white rounded-4 shadow p-xl-5 p-sm-4 p-3'>
                        <h2 className='text-center  mb-4'>Forgot Password</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {success && (
                            <Alert variant="success">
                                Password reset instructions have been sent to your email!
                            </Alert>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail" className="mb-3">
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={loading}
                                    required
                                />
                            </Form.Group>
                            <Button
                                type="submit"
                                className='w-100 py-2'
                                style={{ background: "var(--bg-ltr)", borderColor: "var(--primary-color)" }}
                                disabled={loading}
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Form>
                        <div className="text-center mt-3">
                            <Button
                                variant="link"
                                onClick={() => navigate('/login')}
                                className="text-decoration-none"
                            >
                                Back to Sign In
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default ForgotPassword;
