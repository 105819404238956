import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

const Content6by6 = ({ fs, space, image, content, h1, h2, order1, order2 }) => {
    return (
        <Container className='py-md-5 py-3'>

            {h1 &&
                <h1 className='text-uppercase mb-5 text-center display-4'>{h1}:</h1>
            }
            <Row>
                <Col xs={{ span: 12, order: order1 }} lg={{ span: 6, order: order1 }}>

                    {h2 &&
                        <h1 className='text-uppercase mb-4 display-4'>{h2}:</h1>
                    }
                    {content?.map(data => (
                        <div key={data?.id} className={`d-flex gap-3 gap-xl-4 mb-4 ${space}`}>

                            {data?.icon ? <div>
                                <Image src={data?.icon} alt={data?.heading} />
                            </div> : null}

                            <div>
                                <h4 className="mb-2">{data?.heading}</h4>
                                <p className={`${fs} mb-0`}>{data?.text}</p>
                            </div>
                        </div>
                    ))}

                </Col>
                <Col className='d-flex align-items-xxl-end mb-lg-0 mb-3' xs={{ span: 12, order: order2 }} lg={{ span: 6, order: order2 }}>
                    <div className='w-100 '>
                        <Image
                            src={image}
                            alt="Benefits Section Image"
                            className='w-100 h-100'
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Content6by6;
