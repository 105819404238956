import React from 'react'

const PageHeading = ({ heading, color, fontSize }) => {
    return (
        <>
            <h1
                className=' mb-1 '
                style={{
                    fontSize: fontSize ? fontSize : "24px",
                    fontWeight: 700,
                    lineHeight: "36px",
                    color: color ? color : 'var(--primary-color)', // corrected syntax
                }}
            >
                {heading}
            </h1>
        </>
    )
}

export default PageHeading;
