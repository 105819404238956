import WaitingTable from 'components/adminTable/Table';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { GetWaitingUser } from 'services/admin/admin.service';

const WaitingUser = () => {
    const [loading, setLoading] = useState(true)
    const [tableBody, setTableBody] = useState();
    const tableHeadings = [
        "First Name",
        "Last Name",
        "Email",
        "Company Name",
        "Phone Number",
        "Status",
        // "Action"
    ];
    const waitingUser = async () => {
        try {
            const response = await GetWaitingUser();
            console.log("WAiting", response?.data?.data)
            setTableBody(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        waitingUser()
    }, [])

    return (
        <Container className='mb-5' fluid>
            <h5 className='my-4'>Waiting Users</h5>
            <WaitingTable tableHeadings={tableHeadings} tableBody={tableBody} loading={loading} />


        </Container >
    )
}

export default WaitingUser
