import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { IoMdRemoveCircleOutline } from "react-icons/io";

const ConfirmModal = ({ text, show, handleClose, handleConfirm }) => {

    return (
        <Modal show={show} onHide={handleClose} centered size={"md"}>
            <Modal.Header className='border-0' closeButton>
            </Modal.Header>
            <Modal.Body className='text-center'>

                <IoMdRemoveCircleOutline className='p-3' style={{ fontSize: "80px", color: "red", }} />


                <h3 className='mt-1'> Are You Sure</h3>
                <p className='text-muted'>{text}</p>
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between pt-0'>
                <Button
                    // variant="secondary"
                    type='submit'
                    className='mt-1 px-4 button-style py-2 border-0 bg-secondary btn btn-primary'
                    style={{ borderRadius: '20px', }}
                    onClick={handleClose}
                >
                    No
                </Button>
                <Button
                    variant="primary"
                    type='submit'
                    className='mt-1 px-4 button-style py-2 border-0  btn btn-primary'
                    onClick={handleConfirm}
                    style={{
                        borderRadius: '20px',
                        backgroundColor: '#1cc76d',
                        borderColor: '#1cc76d',
                    }}
                >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
