import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "utils/AuthContext";
import "./global.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toggle/style.css";
import "react-country-state-city/dist/react-country-state-city.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <React.StrictMode>
        <App />
        <ToastContainer />
      </React.StrictMode>
    </AuthProvider>
  </BrowserRouter>
);
