import React from 'react'
import { Card, Image } from 'react-bootstrap'

const TestimonialCard = ({ image, domain, description, name }) => {
    return (
        <Card className="testimonials-card " >
            <div>  <Card.Body>
                <div className="d-flex flex-row  gap-3 align-items-center mb-3">
                    <Image
                        src={image}
                        alt={name}
                        roundedCircle
                        className="object-fit-cover"
                        style={{ width: '50px', height: '50px' }}
                    />
                    <div>
                        <h5 className="mb-0 text-start">{name}</h5>
                        <h6 className="mb-0 text-start text-secondary   "> {domain}</h6>
                    </div>

                </div>
                <Card.Text className="testimonial-text small text-start">
                    {description}
                </Card.Text>
            </Card.Body></div>
        </Card>
    )
}

export default TestimonialCard
