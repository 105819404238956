import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutSection = () => {
    return (
        <div className='about d-flex justify-content-center align-items-center'>
            <Container>
                <Row className='justify-content-center'>
                    <Col md={9} xs={12} className="text-center px-md-0 px-3">
                        <h1 className='text-white display-5 '>About Pest Insights</h1>
                        <p className='text-white fs-5 fw-bold py-3 lh-base'>
                            Pest Insights is a powerful software solution designed to help <span className="text-black">pest control companies monitor and enhance their technicians' performance.</span> With our automated reporting and data analytics, you can make data-driven decisions to improve efficiency and customer satisfaction.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutSection;
