import React from 'react';
import { Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
// import { useNavigate } from 'react-router-dom';

const WaitingTable = ({ tableBody, tableHeadings, loading }) => {
    const skeletonRows = Array(10).fill(null);
    // const navigate = useNavigate();

    // const handleCreateUser = (row) => {
    //     navigate('/add-user', { state: { userData: row } });
    // };

    return (
        <>
            <div className="shadow p-3 rounded-3">
                <Table hover responsive>
                    <thead>
                        <tr>
                            {tableHeadings?.map((th, idx) => (
                                <th className='t-head py-3' style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }} key={idx}>{th}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            skeletonRows.map((_, i) => (
                                <tr key={i}>
                                    {tableHeadings.map((_, idx) => (
                                        <td key={idx}><Skeleton height={20} /></td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            tableBody?.map((row, i) => (
                                <tr
                                    key={i}
                                    className={row.isWaiting ? 'waiting-user ' : 'active-user'}
                                >
                                    <td className='py-3'>{row?.firstname}</td>
                                    <td className='py-3'>{row?.lastname}</td>
                                    <td className='py-3'>{row?.email}</td>
                                    <td className='py-3'>{row?.company}</td>
                                    {row?.phone ? (
                                        <td className='py-3'>+{row?.phone}</td>
                                    ) : (
                                        <td className='py-3'>{row?.phone}</td>
                                    )}
                                    <td className='py-3'>
                                        {row.isWaiting ? (
                                            <span>In Waiting List</span>
                                        ) : (
                                            <span>Approved</span>
                                        )}
                                    </td>
                                    {/* <td className='py-3'>
                                        {!row.isWaiting && (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleCreateUser(row)}
                                                style={{
                                                    borderRadius: '20px',
                                                    backgroundColor: '#1cc76d',
                                                    borderColor: '#1cc76d',
                                                }}
                                                className="btn-sm"
                                            >
                                                Create User
                                            </Button>
                                        )}
                                    </td> */}
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default WaitingTable;
