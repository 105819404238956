import Layout from 'components/layouts/landing/Layout';
import DashboardLayout from 'components/layouts/sidebar/Layout';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { GetUserRoles } from 'utils/auth.utils';
import { useAuth } from 'utils/AuthContext';
import AddUserForm from 'views/admin/AddUserForm';
import CompanyDetails from 'views/admin/CompanyDetails';
import AdminDashboard from 'views/admin/Dashboard';
import WaitingUser from 'views/admin/WaitingUser';
import ForgotPassword from 'views/ForgotPassword';
import NotFound from 'views/NotFound';
import OTP from 'views/Otp';
import ResetPassword from 'views/ResetPassword';
import Signin from 'views/SignIn';
import SignUp from 'views/SignUp';
import BreakDown from 'views/user/BreakDown';
import UserDashboard from 'views/user/Dashboard';
import Individual from 'views/user/Individual';
import Landing from 'views/user/Landing';
import ReService from 'views/user/ReService';
import Setting from 'views/user/Settings';

const Navigation = () => {
    const { isVerified } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    // useEffect(() => {
    //     if (location.pathname === '/') {
    //         if (isVerified) {
    //             navigate('/dashboard');
    //         } else {
    //             navigate('/');
    //         }
    //     }
    // }, [isVerified, navigate, location.pathname]);
    const UserRole = GetUserRoles()
    return (
        <Routes>

            <Route element={<Layout />}>
                <Route path='/*' element={<NotFound />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/otp' element={<OTP />} />
                <Route path='/' element={<Landing />} />
                <Route path='/login' element={<Signin />} />
                <Route path='/waiting-form' element={<SignUp />} />
            </Route>
            <Route element={<DashboardLayout />}>
                {UserRole === "superAdmin" && (
                    <>
                        <Route path='/*' element={<NotFound />} />
                        <Route path='/dashboard' element={<AdminDashboard />} />
                        <Route path='/add-company' element={<AddUserForm />} />
                        <Route path='/dashboard/view-company/:companyId' element={<CompanyDetails />} />
                        <Route path='/waiting-users' element={<WaitingUser />} />

                    </>
                )}
                {(UserRole === "user" || UserRole === "admin") && (
                    <>
                        <Route path='/*' element={<NotFound />} />
                        <Route path='/dashboard' element={<UserDashboard />} />
                        <Route path='/breakdown' element={<BreakDown />} />
                        <Route path='/individual' element={<Individual />} />
                        <Route path='/reservice' element={<ReService />} />
                        <Route path='/settings' element={<Setting />} />
                        {UserRole === "admin" && (
                            <Route path='/user-management' element={<CompanyDetails />} />
                        )}
                    </>
                )}

            </Route>


        </Routes>
    );
};

export default Navigation;
