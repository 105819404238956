import React, { useEffect, useState, useRef } from 'react';
import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-scroll';
import logo from 'assets/PestInsightsLogo_rectangle.png';
import { menuItems } from 'components/shared/MenuItems';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [activeLink, setActiveLink] = useState("");

    const sectionsRef = useRef({});

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.6,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveLink(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        menuItems.forEach((tab) => {
            const section = document.getElementById(tab.path);
            if (section) {
                sectionsRef.current[tab.path] = section;
                observer.observe(section);
            }
        });

        return () => {
            Object.values(sectionsRef.current).forEach((section) => observer.unobserve(section));
        };
    }, [location]);;

    return (
        <Navbar
            expanded={expanded}
            expand="lg"
            className={`p-0 border-bottom w-100 top-0 bg-white ${isSticky ? 'position-sticky shadow-sm' : ''}`}
            style={{ zIndex: 1030 }}
        >
            <Container>
                <Navbar.Brand className="p-0 py-2" as={NavLink} to="/">
                    <Image src={logo} alt="Pest Insights" fluid style={{ height: '50px' }} />
                </Navbar.Brand>
                <Navbar.Toggle
                    className="border-0"
                    aria-controls="navbarScroll"
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? <FaTimes /> : <FaBars />}
                </Navbar.Toggle>
                <Navbar.Collapse className="pb-lg-0 pb-2">
                    <Nav className="mx-auto gap-lg-3">
                        {menuItems?.map((tab) => (
                            <Link
                                key={tab?.label}
                                to={tab?.path}
                                spy
                                activeClass="ActiveLink"
                                className={`nav-link pointer ${activeLink === tab?.path ? 'ActiveLink' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setExpanded(false);

                                    if (location.pathname !== '/') {
                                        navigate('/', { state: { targetSection: tab?.path } });
                                    } else {
                                        setActiveLink(tab?.path);
                                        document.getElementById(tab?.path)?.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }}
                            >
                                {tab?.label}
                            </Link>
                        ))}
                    </Nav>
                    <Form className="d-flex">
                        <Button onClick={() => navigate("/waiting-form")} className="bg-primary-ltr px-4 border-0">
                            <small>Join Now</small>
                        </Button>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
