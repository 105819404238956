import CustomChart from 'components/charts/Line';
import Filter from 'components/shared/Filter';
import DashboardTable from 'components/shared/Table';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Individual = () => {

    const [filter, setFilter] = useState(90)

    const tableHeadings = [
        "Location",
        "Num Appt",
        "Avg. Tech Time on Site",
        "Avg. Production Value",
        "Total Production",
        "Reservice Rate"
    ];

    const tableBody = [
        ["Scranton", 120, "1.5 hours", "$2,000", "$240,000", "5%"],
        ["Green Earth", 95, "2 hours", "$1,800", "$171,000", "7%"],
        ["Health Plus", 110, "1.2 hours", "$2,200", "$242,000", "4%"],
        ["EduConnect", 85, "1.8 hours", "$1,600", "$136,000", "6%"],
        ["FinCorp", 75, "2.2 hours", "$2,400", "$180,000", "8%"],
        ["Tech Innovators", 130, "1.6 hours", "$2,300", "$299,000", "5%"],
        ["Bright Future", 140, "1.4 hours", "$2,100", "$294,000", "4%"],
        ["Eco Solutions", 105, "2 hours", "$1,900", "$199,500", "6%"],
        ["NextGen", 100, "1.7 hours", "$2,000", "$200,000", "7%"],
        ["HealthCare Pro", 115, "1.3 hours", "$2,250", "$258,750", "3%"]
    ];
    const filters = [30
        , 90
        , 6
        , 12]

    useEffect(() => {


        GetResult()
    }, [filter])
    const GetResult = async () => {
        console.log("result search")
    }
    const data = [
        { name: '0k', uv: 4000000, pv: 2400000 },
        { name: '10k', uv: 3000000, pv: 1398000 },
        { name: '20k', uv: 2000000, pv: 9800000 },
        { name: '30k', uv: 2780000, pv: 3908000 },
        { name: '40k', uv: 1890000, pv: 4800000 },
        { name: '50k', uv: 2390000, pv: 3800000 },
        { name: '60k', uv: 3490000, pv: 4300000 },
        { name: '70k', uv: 3200000, pv: 4200000 },
        { name: '80k', uv: 2800000, pv: 4100000 },
        { name: '90k', uv: 3000000, pv: 3900000 },
        { name: '100k', uv: 2900000, pv: 3700000 },
        { name: '120k', uv: 3100000, pv: 3500000 },
    ];
    const color1 = "#FF9FB8"
    const color2 = "#1CC76D"

    return (
        <Container className='mb-5' fluid>
            <h1>Individual Report</h1>
            <Filter filters={filters} setFilter={setFilter} activeFilter={filter} />

            <Row className='py-4 gap-xl-0 gap-4'>
                <Col xs={12}>
                    <section className='p-4 pb-0 rounded-4'>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Statistics</h5>
                        </div>


                        <CustomChart
                            data={data}
                            dataKeys={['uv', "pv"]}
                            xAxisKey="name"
                            defs={false}
                            height={400}
                            strokeWidth={4}
                            defColors={[color1, color2]}
                            vr={false}
                            colors={{
                                stroke: [color1, color2],
                                fill: [color1, color2],
                            }}
                            legend={true}
                            legendPosition={{ left: 0, top: 0 }}
                            grid={false}
                        />
                    </section>

                </Col>


            </Row >
            <h5 className='my-4'>Individual Report Details</h5>
            <DashboardTable tableHeadings={tableHeadings} tableBody={tableBody} />


        </Container >
    );
};

export default Individual;
