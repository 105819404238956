import { GetApiData, PostFormData } from "utils/http-client";
export const updateUserDetail = function (data) {
    return GetApiData(`/user/`, 'PUT', data, true);
}
export const changePassword = function (data) {
    return GetApiData(`/user/change-password`, 'POST', data, true);
}
export const getUserDetail = function () {
    return GetApiData(`/user/`, 'GET', null, true);
}
export const profileUser = function (data) {
    return PostFormData(`/upload/`, 'POST', data, true);
}