import React from 'react';
import { Element } from 'react-scroll';
import About from 'components/landing/About';
import Features from 'components/landing/Features';
import Hero from 'components/landing/Hero';
import Testimonials from 'components/landing/Testimonials';
import Works from 'components/landing/Works';
import Benefits from 'components/landing/Benefits';
import ReadyToJoin from 'components/landing/ReadyToJoin';

const Landing = () => {
    return (
        <>
            <Hero />

            <Element name="about" >
                <About />
            </Element>
            <Element name="features" >
                <Features />
            </Element>
            <Element name="works" >
                <Works />
            </Element>
            <Element name="benefits" >
                <Benefits />
            </Element>
            <Element name="testimonials" >
                <Testimonials />
            </Element>
            <ReadyToJoin />
        </>
    );
}

export default Landing;
