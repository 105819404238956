import CustomChart from 'components/charts/Line';
import Filter from 'components/shared/Filter';
import DashboardTable from 'components/shared/Table';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { FaCaretUp, FaUserAlt } from 'react-icons/fa';
import { FaCalendarDays, FaChartColumn } from 'react-icons/fa6';

const BreakDown = () => {

    const [filter, setFilter] = useState(90)

    const tableHeadings = [
        "Location",
        "Num Appt",
        "Avg. Tech Time on Site",
        "Avg. Production Value",
        "Total Production",
        "Reservice Rate"
    ];

    const tableBody = [
        ["Scranton", 120, "1.5 hours", "$2,000", "$240,000", "5%"],
        ["Green Earth", 95, "2 hours", "$1,800", "$171,000", "7%"],
        ["Health Plus", 110, "1.2 hours", "$2,200", "$242,000", "4%"],
        ["EduConnect", 85, "1.8 hours", "$1,600", "$136,000", "6%"],
        ["FinCorp", 75, "2.2 hours", "$2,400", "$180,000", "8%"],
        ["Tech Innovators", 130, "1.6 hours", "$2,300", "$299,000", "5%"],
        ["Bright Future", 140, "1.4 hours", "$2,100", "$294,000", "4%"],
        ["Eco Solutions", 105, "2 hours", "$1,900", "$199,500", "6%"],
        ["NextGen", 100, "1.7 hours", "$2,000", "$200,000", "7%"],
        ["HealthCare Pro", 115, "1.3 hours", "$2,250", "$258,750", "3%"]
    ];
    const filters = [30
        , 90
        , 6
        , 12]

    useEffect(() => {


        GetResult()
    }, [filter])
    const GetResult = async () => {
        console.log("result search")
    }

    const data = [
        { name: 'Jan', uv: 4000000, },
        { name: 'Feb', uv: 3000000, },
        { name: 'Mar', uv: 2000000, },
        { name: 'Apr', uv: 2780000, },
        { name: 'May', uv: 1890000, },
        { name: 'Jun', uv: 2390000, },
        { name: 'Jul', uv: 3490000, },
        { name: 'Aug', uv: 3200000, },
        { name: 'Sep', uv: 2800000, },
        { name: 'Oct', uv: 3000000, },
        { name: 'Nov', uv: 2900000, },
        { name: 'Dec', uv: 3100000, },
    ];

    const color1 = "#26C080"
    const color2 = "#26C080"
    return (
        <Container className='mb-5' fluid>
            <h1>Breakdown</h1>
            <Filter filters={filters} setFilter={setFilter} activeFilter={filter} />

            <Row className='py-4 gap-xl-0 gap-4'>
                <Col xl={8} xs={12}>
                    <section style={{
                        backgroundColor: "#F6F6FB"
                    }} className='p-4 rounded-4'>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Avg. Production Value</h5>
                            <h2 style={{ color: "var(--primary-color)" }}>$42.43M</h2>
                        </div>
                        <hr />
                        <CustomChart
                            data={data}
                            dataKeys={['uv']}
                            xAxisKey="name"
                            defs={false}
                            height={270}
                            strokeWidth={4}
                            defColors={[color1, color2]}
                            vr={false}
                            colors={{
                                stroke: [color1, color2],
                                fill: [color1, color2],
                            }}
                            legend={false}
                            grid={false}
                        />


                    </section>

                </Col>
                <Col xl={4} xs={12}>
                    <Row className='mb-3 gap-3 d-flex flex-sm-row flex-column'>
                        <Col className='p-0 '>
                            <Card className='breakdown-card rounded-4'>
                                <div className="d-flex justify-content-between py-4">
                                    <div>
                                        <FaChartColumn className="card-icon" />
                                    </div>
                                    <div>
                                        <FaCaretUp className='dropdown-icon' /> <span>12%</span>
                                    </div>
                                </div>
                                <h2>42.34%</h2>
                                <p className="mb-0 small">Avg. Production Value</p>
                            </Card>
                        </Col>

                        <Col className='p-0 '>
                            <Card className='breakdown-card rounded-4'>
                                <div className="d-flex justify-content-between py-4">
                                    <div>
                                        <FaCalendarDays className="card-icon" />
                                    </div>
                                    <div>
                                        <FaCaretUp className='dropdown-icon' /> <span>2.1%</span>
                                    </div>
                                </div>
                                <h2>29.09</h2>
                                <p className="mb-0 small">Avg. Time on Site</p>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className='p-0'>
                            <Card className='breakdown-card rounded-4'>
                                <div className="d-flex justify-content-between py-4">
                                    <div>
                                        <FaUserAlt className="card-icon" />
                                    </div>
                                    <div>
                                        <FaCaretUp className='dropdown-icon' /> <span>2.1%</span>
                                    </div>
                                </div>
                                <h2>1.02%</h2>
                                <p className="mb-0 small">Reservice Rate</p>
                            </Card>
                        </Col>
                    </Row>
                </Col>


            </Row >
            <h5 className='my-4'>Breakdown Details</h5>

            <DashboardTable tableHeadings={tableHeadings} tableBody={tableBody} />


        </Container >
    );
};

export default BreakDown;
