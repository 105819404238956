import Dashboard from 'assets/Dashboard.png'
import Growarrow from 'assets/svg/growarrow.svg'
import Marketing from 'assets/svg/MarketingResearch.svg'
import Techrobo from 'assets/svg/techrobo.svg'
import Content6by6 from 'components/shared/Content6by6'
const Features = () => {

    const features = [


        { id: 0, icon: Marketing, heading: "Technician Performance Monitoring", text: "Track key performance indicators (KPIs) for your technicians, including job completion rates, customer feedback, and efficiency metrics." },
        { id: 1, icon: Techrobo, heading: "Automated Reporting", text: "Receive comprehensive reports automatically, allowing you to focus on decision-making rather than data collection." },
        { id: 2, icon: Growarrow, heading: "Data-Driven Insights", text: "Leverage powerful analytics to identify trends, optimize operations, and improve overall service quality." }
    ]
    return (

        <Content6by6 space={"pb-5 pt-2"} fs={"fs-4"} content={features} h1={""} h2={"Features"} order1={1} order2={2} image={Dashboard} />



    )
}

export default Features
