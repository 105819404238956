import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Card, Alert } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addCompany } from 'services/admin/admin.service';
import { CountrySelect } from 'react-country-state-city';
import ConfirmModal from 'components/modals/ConfirmModal';
import { toast } from 'react-toastify';

const AddUserForm = () => {
    const location = useLocation();
    const { id } = useParams();
    const [text, setText] = useState();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const { userData } = location.state || {};
    const [formData, setFormData] = useState({
        companyName: '',
        companyAddress: '',
        country: '',
        companyPhone: '',
        adminEmail: '',
        adminFirstName: '',
        adminLastName: '',
        adminPhone: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    useEffect(() => {
        if (userData) {
            setFormData({
                companyName: userData?.company || '',
                adminFirstName: userData?.firstname || '',
                adminLastName: userData?.lastname || '',
                adminEmail: userData?.email || '',
                adminPhone: userData?.phone || '',
                country: userData?.country || '',
                companyAddress: '',
                companyPhone: '',
            });
        }
    }, [userData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setError((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const validateForm = () => {
        let formErrors = {};
        if (!formData.companyName) formErrors.companyName = 'Company name is required';
        if (!formData.companyAddress) formErrors.companyAddress = 'Company address is required';
        if (!formData.country) formErrors.country = 'Country is required';
        if (!formData.companyPhone) formErrors.companyPhone = 'Company phone is required';
        if (!formData.adminEmail) formErrors.adminEmail = 'Admin email is required';
        if (!formData.adminFirstName) formErrors.adminFirstName = 'Admin first name is required';
        if (!formData.adminLastName) formErrors.adminLastName = 'Admin last name is required';
        if (!formData.adminPhone) formErrors.adminPhone = 'Admin phone is required';
        return formErrors;
    };

    const handleFormSubmit = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setError(formErrors);
        } else {
            setText('Are You sure you want to create this company?')
            setModalShow(true);
        }
    };

    const handleCreate = async () => {
        setLoading(true);
        setModalShow(false)
        setError({});
        const toastId = toast.loading("Adding Company...");
        try {
            await addCompany(formData);
            setFormData({
                companyName: '',
                companyAddress: '',
                country: '',
                companyPhone: '',
                adminEmail: '',
                adminFirstName: '',
                adminLastName: '',
                adminPhone: '',
            });
            toast.update(toastId, {
                render: "Company Added successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            navigate('/dashboard');
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.error || 'Server error During Deactivating',
                type: "error",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            setError({ general: error?.response?.data?.error || 'An error occurred while adding the user. Please try again.' });
        } finally {
            setLoading(false);
            setModalShow(false);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Container fluid>
            <h1>User Management - Add Company</h1>
            <Form className="pt-2">
                {/* Company Info Card */}
                <Card className="p-4 border-0 mt-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <h4 style={{ color: '#4E4E4E' }} className="my-4">
                        Company Info
                    </h4>
                    {error.general && <Alert variant="danger">{error.general}</Alert>}
                    <Row className="my-3">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyName">
                                <Form.Label className='text-secondary'>Company Name</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.companyName}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyAddress">
                                <Form.Label className='text-secondary'>Company Address</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="companyAddress"
                                    value={formData.companyAddress}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.companyAddress}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyAddress}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCountry" className='custom-input'>
                                <Form.Label className='text-secondary'>Country</Form.Label>
                                {/* <Form.Control
                                    type="text"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.country}
                                /> */}
                                <CountrySelect
                                    inputClassName="border-0"
                                    placeHolder="Select Country"
                                    value={formData.country}
                                    onChange={(country) => setFormData({ ...formData, country: country.name })}
                                />
                                <Form.Control.Feedback type="invalid">{error.country}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyPhone">
                                <Form.Label className='text-secondary'>Company Phone</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="companyPhone"
                                    value={formData.companyPhone}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.companyPhone}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyPhone}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card>

                {/* Admin Info Card */}
                <Card className="p-4 border-0 mt-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <h4 style={{ color: '#4E4E4E' }} className="my-4">
                        Admin Info
                    </h4>
                    <Row className="mt-3">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formAdminFirstName">
                                <Form.Label className='text-secondary'>Admin First Name</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="adminFirstName"
                                    value={formData.adminFirstName}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.adminFirstName}
                                />
                                <Form.Control.Feedback type="invalid">{error.adminFirstName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formAdminLastName">
                                <Form.Label className='text-secondary'>Admin Last Name</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="adminLastName"
                                    value={formData.adminLastName}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.adminLastName}
                                />
                                <Form.Control.Feedback type="invalid">{error.adminLastName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formAdminEmail">
                                <Form.Label className='text-secondary'>Admin Email</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="email"
                                    name="adminEmail"
                                    value={formData.adminEmail}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.adminEmail}
                                />
                                <Form.Control.Feedback type="invalid">{error.adminEmail}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formAdminPhone">
                                <Form.Label className='text-secondary'>Admin Phone</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="adminPhone"
                                    value={formData.adminPhone}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.adminPhone}
                                />
                                <Form.Control.Feedback type="invalid">{error.adminPhone}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card>

                <div className="d-flex justify-content-end mt-4">
                    <Button
                        className="px-4 py-2 submit-btn-hover"
                        style={{
                            borderRadius: '30px',
                            background: 'linear-gradient(90deg, #1cc76d 0%, #007a48 100%)',
                            borderColor: '#1cc76d'
                        }}
                        onClick={handleFormSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </Form>

            {/* <CreateModal
                loading={loading}
                show={modalShow}
                onHide={() => setModalShow(false)}
                onCreate={handleCreate}
            /> */}
            <ConfirmModal
                text={text}
                show={modalShow}
                handleClose={() => setModalShow(false)}
                handleConfirmDelete={handleCreate}
            />
        </Container>
    );
};

export default AddUserForm;
